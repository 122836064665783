import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React, { Component } from "react";
import Sofa from "../assets/1.jpg";
import Sofa3 from "../assets/3.1.png";
import Sofa4 from "../assets/4.1.png";
import "../App.css";

class DemoCarousel extends Component {
  render() {
    return (
      <Carousel
        // dynamicHeight={true}
        autoPlay={true}
        transitionTime={1000}
        interval={2000}
        infiniteLoop={true}
      >
        <div>
          <img src={Sofa4} alt="sofa" />
        </div>
        <div>
          <img src={Sofa3} alt="sofa2" />
        </div>
        <div>
          <img src={Sofa} alt="sofa3" />
        </div>
      </Carousel>
    );
  }
}
export default DemoCarousel;
