import React from "react";
import CarouselSection from "../components/CarouselSection";
import ProductCard from "../components/ProductCard";
import HighChair from "../assets/highChair.jpg";
import About from "./About";

const productData = [
  {
    title: "Sofa sets",
    desc: "A box-shadow CSS generator that helps you quickly generate box-shadow CSS declarations for your website. It comes with many options and it demonstrates instantly. If you want to have cool fonts, please also try our font keyboard to help easily get fonts at Font Keyboard iOS app and Font Keyboard Android app.",
    image:
      "https://images.unsplash.com/photo-1615876234886-fd9a39fda97f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3132&q=80",
    flip: false,
    link: "sofa-sets",
  },
  {
    title: "High chairs",
    desc: "A box-shadow CSS generator that helps you quickly generate box-shadow CSS declarations for your website. It comes with many options and it demonstrates instantly. If you want to have cool fonts, please also try our font keyboard to help easily get fonts at Font Keyboard iOS app and Font Keyboard Android app.",
    image: HighChair,
    flip: true,
    link: "high-chairs",
  },
  {
    title: "Arm chairs",
    desc: "A box-shadow CSS generator that helps you quickly generate box-shadow CSS declarations for your website. It comes with many options and it demonstrates instantly. If you want to have cool fonts, please also try our font keyboard to help easily get fonts at Font Keyboard iOS app and Font Keyboard Android app.",
    image:
      "https://images.unsplash.com/photo-1633025097735-c60ed41e6c9d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
    flip: false,
    link: "arm-chairs",
  },
  {
    title: "Bed back",
    desc: "A box-shadow CSS generator that helps you quickly generate box-shadow CSS declarations for your website. It comes with many options and it demonstrates instantly. If you want to have cool fonts, please also try our font keyboard to help easily get fonts at Font Keyboard iOS app and Font Keyboard Android app.",
    image:
      "https://images.unsplash.com/photo-1505693416388-ac5ce068fe85?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80",
    flip: true,
    link: "bed-backs",
  },
];
const Home = () => {
  return (
    <div className="App">
      <CarouselSection />
      <About hideImage={true} />
      {productData.map((product, i) => {
        return (
          <>
            <ProductCard
              key={product.title + i}
              title={product.title}
              image={product.image}
              flip={product.flip}
              link={product.link}
            />
            <hr style={{ width: "90%", borderTop: "1px solid lightgrey" }} />
          </>
        );
      })}
    </div>
  );
};

export default Home;
