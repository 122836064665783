import React from "react";
import "../App.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import Logo from "../assets/logo.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BusinessIcon from "@mui/icons-material/Business";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="d-flex justify-content-center p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href="https://www.facebook.com" className="me-4 text-reset">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com" className="me-4 text-reset">
              <InstagramIcon />
            </a>
            <a
              href="https://www.google.com/search?q=ar+creative+furnishing+pune&rlz=1C5GCEM_enIN1046IN1046&oq=ar+creative+furnishing+pune&aqs=chrome..69i57.7123j0j7&sourceid=chrome&ie=UTF-8"
              className="me-4 text-reset"
            >
              <GoogleIcon />
            </a>
          </div>
        </section>

        <section className="">
          <div className="container text-center mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    src={Logo}
                    alt="logo"
                    height={40}
                    style={{ borderRadius: "5px", marginRight: "10px" }}
                  />
                </h6>
                <p>
                  Manufacturer and Cushion work of Sofa Sets, Couch, Center
                  Table, High-Chair, Armchair, Bed-back
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <Link to="/Products/sofa-sets" className="text-reset">
                    Sofa set
                  </Link>
                </p>
                <p>
                  <Link to="/Products/high-chairs" className="text-reset">
                    High chair
                  </Link>
                </p>
                <p>
                  <Link to="/Products/arm-chairs" className="text-reset">
                    Arm chair
                  </Link>
                </p>
                <p>
                  <Link to="/Products/bed-backs" className="text-reset">
                    Bed back
                  </Link>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <BusinessIcon />
                  <p>
                    21/4 Raghoba Patil Nagar, Near HDFC Bank, Kharadi, Pune
                    411014, Maharashtra
                  </p>
                </p>
                {/*   <p>
                  <i className="fas fa-envelope me-3"></i>
                  info@gmail.com
                </p> */}
                <p>
                  <LocalPhoneIcon />
                  <p>+91 9112 60 4650</p>
                </p>
              </div>
            </div>
          </div>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "darkslategrey", color: "whitesmoke" }}
        >
          © 2023 Copyright &nbsp;
          <a className="text-reset fw-bold" href="https://mdbootstrap.com/">
            AR Creative Furnishings
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
