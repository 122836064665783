import Nav from "./components/Nav";
import { Route, Routes } from "react-router-dom";
import Products from "./components/Products";
import About from "./components/About";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { sofas, highChairs, armChairs, bedBacks } from "./assets/images";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Nav />
      <div style={{ marginTop: "50px" }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="Home" element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="Products" element={<Products />} />
          <Route
            path="Products/sofa-sets"
            element={<Products itemData={sofas} />}
          />
          <Route
            path="Products/high-chairs"
            element={<Products itemData={highChairs} />}
          />
          <Route
            path="Products/arm-chairs"
            element={<Products itemData={armChairs} />}
          />
          <Route
            path="Products/bed-backs"
            element={<Products itemData={bedBacks} />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
