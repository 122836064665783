import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const About = ({ hideImage }) => {
  return (
    <Grid container className="App" style={{ background: "#eee" }}>
      {!hideImage && (
        <Grid item sm={6}>
          <img
            width={"100%"}
            src={`https://images.unsplash.com/photo-1630585308572-f53438fc684f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=550&q=80`}
            srcSet={`https://images.unsplash.com/photo-1630585308572-f53438fc684f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=550&q=80`}
            alt="sofa"
            loading="lazy"
          />
        </Grid>
      )}
      <Grid
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        flexDirection={"column"}
        p={hideImage ? "5%" : "10%"}
        item
        sm={hideImage ? 12 : 6}
      >
        <Typography variant="h5" mt={hideImage ? 0 : 5} p={2}>
          Manufacturer and Cushion work of Sofa Sets, Couch, Center Table,
          High-Chair, Armchair, Bed-back
        </Typography>
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Designed as per your requirement to be complementary in Style, Color, Fabric material which intend to provide a cohesive and coordinated look" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Choice of any styles, Selection from variety of Colors and leather, fabric, Synthetic materials etc. Size to fit perfectly into spaces of the room, Options from Budget friendly to High end" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding mb={hideImage ? 0 : 5}>
            <ListItemButton>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Best Quality and Price is certain; hence all our Product comes with minimum 5 years of warranty, Up to 60% of buy Back guarantee, Referral Bonus offers and Exchange offers with your old Furniture" />
            </ListItemButton>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default About;
