import React from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function ProductCard({ title, image, flip, link }) {
  return (
    <Grid container spacing={2} flexDirection={flip ? "row-reverse" : "row"}>
      <Grid
        item
        sm={6}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h4" gutterBottom mt="50px">
          {title}
        </Typography>
      </Grid>
      <Grid item sm={6} className="img-container" mt="50px" mb="50px">
        <Link to={`/products/${link}`}>
          <img
            src={image}
            alt="sofa"
            width={"100%"}
            style={{
              borderRadius: "10px",
              boxShadow: "3px 7px 46px -10px rgba(0,0,0,0.75)",
            }}
          />
        </Link>
      </Grid>
    </Grid>
  );
}
