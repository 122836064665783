import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";

export default function Products({ itemData }) {
  if (itemData)
    return (
      <Box
        pl="10%"
        pr="10%"
        sx={{
          width: "100%",
          height: "100vh",
          overflowY: "scroll",
        }}
        mt="80px"
      >
        <ImageList variant="masonry" cols={4} gap={8}>
          {itemData.map((item, i) => (
            <Link to={item.img} target="_blank" rel="noopener noreferrer">
              <ImageListItem key={item.img + i}>
                <img
                  src={`${item.img}`}
                  srcSet={`${item.img}`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            </Link>
          ))}
        </ImageList>
      </Box>
    );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "70vh",
        flexWrap: "wrap",
      }}
    >
      <ProductCategory />
    </div>
  );
}

function ProductCategory() {
  const categories = [
    {
      name: "Sofa sets",
      link: "sofa-sets",
      img: "https://images.unsplash.com/photo-1550581190-9c1c48d21d6c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2218&q=80",
    },
    {
      name: "High chairs",
      link: "high-chairs",
      img: "https://images.unsplash.com/photo-1519643225200-94e79e383724?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2076&q=80",
    },
    {
      name: "Arm chairs",
      link: "arm-chairs",
      img: "https://images.unsplash.com/photo-1633025097735-c60ed41e6c9d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80",
    },
    {
      name: "Bed back",
      link: "bed-backs",
      img: "https://images.unsplash.com/photo-1582582621959-48d27397dc69?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2069&q=80",
    },
  ];
  return categories.map((v) => {
    return (
      <Link to={`/Products/${v.link}`}>
        <Card
          sx={{
            width: 270,
            marginTop: "100px",
            marginRight: "20px",
            height: "250px",
          }}
        >
          <CardActionArea>
            <CardMedia component="img" height="170" image={v.img} alt="sofa" />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {v.name}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    );
  });
}
